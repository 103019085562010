angular
    .module('annexaApp')
    .component('annexaAuditAutomatism',{
        templateUrl: './components/admin/annexa-audit-automatism/annexa-audit-automatism.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.seeRegister = function (item) {
        		var closeFunction = function(){
					var modalExpAux = this;
					modalExpAux.close();
				}
        		
        		var modal = angular.copy(CommonAdminModals.modalAuditAutomatismRegister);
				modal.annexaFormly.options = {};
				modal.annexaFormly.model = {modal_body:{}};
				var auditAutomatismRegisterField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'auditAutomatismRegister'");
				if(auditAutomatismRegisterField) {
					auditAutomatismRegisterField.data.register = item;
				}
				AnnexaFormlyFactory.showModal('modalAutomatismAuditRegister', modal, closeFunction, false);		
            };
            
        	this.$onInit = function () {
        		if(!vm.model){
        			vm.model = {auditAutomatism:{}};
        		}else if(!vm.model.auditAutomatism){
        			vm.model.auditAutomatism = {};
        		}
            }
        }],
        bindings: {
        	model: '=',
        }
    })
	.component('annexaAuditAutomatismRegister',{
        templateUrl: './components/admin/annexa-audit-automatism/annexa-audit-automatism-register.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            this.$onInit = function () {
				if(!vm.register){
					vm.register = {};
				}
            }
        }],
        bindings: {
            register: '='
        }
    })